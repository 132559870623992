import React, { useState, useEffect } from 'react';
import Navbar from './Navigation/Navbar';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { FacebookOutlined, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { t } from 'i18next';
import { IsAndroid } from '../Components/Shared/IsAndroid';
import { IsIos } from '../Components/Shared/IsIos';
import { useNavigate } from 'react-router-dom';
import AboutUsPictureJakub from '../Assets/JaIntimityFestival.webp';



export default function AboutUs() {

    const theme = useTheme();
    const navigate = useNavigate();

    const redirectToContact = () => {
        // Naviguje na hlavní stránku a nastaví hash
        navigate('/#contact');
    };

    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    return (
        <div>
            <Stack >
                <Navbar />
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: '60px', padding: '2em', gap: '20px' }}>
                <div style={{ textAlign: 'center' }}><img src={AboutUsPictureJakub} style={{objectFit: 'cover', width: '100%', borderColor: '#f34848', borderStyle: 'solid', borderRadius: '16px', borderWidth: '1px', maxHeight: '240px' }} /></div>
                    <Typography variant='h2'>O NÁS: CESTA K LEPŠÍM VZTAHŮM</Typography>
                    <Typography variant='h4' color='primary.main'>NAŠE MISE</Typography>
                    <Typography variant='body1'>Pomáháme lidem budovat smysluplné a harmonické vztahy. Věříme, že láska je klíčem k šťastnějšímu životu, a naše aplikace LOVEGUARD je nástrojem, který vás na této cestě provede.</Typography>

                    <Typography variant='h4' color='primary.main'>NÁŠ PŘÍBĚH: OD MYŠLENKY K REALITĚ</Typography>
                    <Typography variant='body1'>Všechno začalo v momentě sebereflexe na mých symbolických 33. narozeninách. Myšlenka na nástroj, který by partnerům pomáhal uvědomit si hodnotu vztahu, se zrodila z mé vlastní zkušenosti workoholika. Uvědomil jsem si, že láska a pozornost nemohou být jen vedlejším produktem každodenní rutiny.</Typography>
                    <Typography variant='body1'>Začal jsem vytvářet LOVEGUARD – projekt, který měl za cíl zlepšit statistiky rozpadajících se vztahů a pomoci párům, aby našly k sobě cestu. Prošel jsem výzvami, učil se nové dovednosti a na podzim 2023 jsem s prototypem aplikace vystoupil před investory v programu Výkop. Díky jejich podpoře a mé odhodlanosti se LOVEGUARD stal realitou.</Typography>

                    <Typography variant='h4' color='primary.main'>KDO JSME</Typography>
                    <Typography variant='body1' >Jsme startup z Liberce, který spojuje technologii, psychologii a vášeň pro zlepšování mezilidských vztahů. Náš tým tvoří:
                        <ul style={{ marginLeft: '20px' }}>
                            <li>Jakub Forman – zakladatel a CEO, produktový designer a vizionář projektu LOVEGUARD.</li>
                            <li>Lukáš Kluch – spoluzakladatel, jehož podpora a technický um jsou klíčové při rozvoji aplikace.</li>
                        </ul>
                        
                    </Typography>

                    <Typography variant='h4' color='primary.main'>NAŠE HODNOTY</Typography>
                    <ol style={{ marginLeft: '20px' }}>
                        <li><b>Síla lásky</b> – Věříme, že vztahy mohou růst, pokud jsou obohaceny láskou, komunikací a vzájemným pochopením.</li>
                        <li><b>Empatie</b> – Nasloucháme našim uživatelům a jejich potřebám. Naše produkty jsou navrženy s cílem opravdu pomoci.</li>
                        <li><b>Autenticita</b> – Jsme upřímní v tom, co děláme, a usilujeme o to, aby náš přístup inspiroval ostatní k opravdovému spojení.</li>
                        <li><b>Inovace pro vztahy</b> – Neustále hledáme nové způsoby, jak posilovat vztahy a zlepšovat nástroje, které je podporují.</li>
                        <li><b>Udržitelnost</b> – Zaměřujeme se na dlouhodobé hodnoty, které mají smysl nejen pro jednotlivce, ale i pro společnost jako celek.</li>
                    </ol>

                    <Typography variant='h4' color='primary.main'>NAŠE APLIKACE</Typography>
                    <Typography variant='body1' >LOVEGUARD není jen aplikace, ale váš osobní průvodce na cestě za lepším vztahem. Pomáhá vám:
                        <ul style={{ marginLeft: '20px' }}>
                            <li>Identifikovat potřeby vás i vašeho partnera.</li>
                            <li>Plánovat romantická gesta a vytvářet prostor pro společné chvíle.</li>
                            <li>Posilovat komunikaci a vzájemné porozumění.</li>
                            <li>Rozvíjet vztah prostřednictvím zábavných výzev a inspirativních nápadů.</li>
                        </ul>
                    </Typography>

                    <Typography variant='h4' color='primary.main'>PODĚKOVÁNÍ</Typography>
                    <Typography variant='body1'>LOVEGUARD je důkazem toho, že každý velký sen začíná malým krokem. Děkujeme všem, kteří nám pomohli, od rodiny a přátel až po investory a partnery. Tento projekt je věnován všem, kteří věří v sílu lásky a osobního růstu.</Typography>
                    <Typography variant='body1'>Připojte se k nám na této cestě!</Typography>
                    <Button variant='outlined' onClick={redirectToShop}>Stáhněte si LOVEGUARD nyní na App Store nebo Google Play.</Button>


                </Box>

                <div id="contact" style={{ height: 'auto', width: '100%', background: '#272727', zIndex: 1000 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '2em' }}>
                        <Button target="_blank" href='https://www.facebook.com/profile.php?id=100089619233058'><FacebookOutlined></FacebookOutlined></Button>
                        <Button target="_blank" href='https://www.instagram.com/loveguard_official/'><Instagram></Instagram></Button>
                        <Button target="_blank" href='https://twitter.com/Loveguard_tweet'><Twitter></Twitter></Button>
                        <Button target="_blank" href='https://www.youtube.com/@loveguard6430'><YouTube></YouTube></Button>
                        <Button target="_blank" href='https://www.linkedin.com/company/loveguard/'><LinkedIn></LinkedIn></Button>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>{t('Contact')}</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>Jakub Forman</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>forman@loveguard.cloud</Typography>
                            </div>
                            <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/TermsAndConditionsApp', '_blank') }}>Smluvní podmínky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/PrivacyPolicyApp', '_blank') }}>Zásady ochrany osobních údajů</Button>
                            </div>
                        </Box>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Společnost</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>LOVEGUARD s.r.o.</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' marginBottom='40px' color='white'>IČO: 21908451</Typography>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Pro Média</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/Erro5FSWYJhCumBMFMXNhuABS0I8DMPOQAKeHCAczpqT3w?e=a9wNAM', '_blank') }}>Loga a fotky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/EgefSryjT5JOpSEgeeobFc0BGBFn5nraZ8r47duFLtCMbQ?e=d83xZ2', '_blank') }}>Zmíňky o nás</Button>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Mapa Stránek</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { navigate('/') }}>Aplikace</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { navigate('/AboutUs') }}>O nás</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { navigate('/#vyzvy') }}>Výzvy</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { navigate('/quiz') }}>Kvíz</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/ebook') }}>Ebook</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { navigate('/#contact') }}>Kontakt</Button>
                                {/* <Button variant='text' size='small' onClick={() => {
                                    const newWindow = window.open('/'); // Otevře hlavní stránku
                                    if (newWindow) {
                                        newWindow.onload = () => {
                                            newWindow.location.hash = '#contact'; // Nastaví hash až po načtení
                                        };
                                    }
                                }}>Kontakt</Button> */}
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={redirectToShop}>Stáhnout APP</Button>
                            </div>

                        </Box>
                    </Box>
                </div>
            </Stack>
        </div>
    )
}
