import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Navbar from './Navigation/Navbar'
import { IsAndroid } from './Shared/IsAndroid';
import { IsIos } from './Shared/IsIos';

export default function AppDownload() {

    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    useEffect(() => {
        redirectToShop();
    }, []);

    useEffect(() => {
        console.log("Spouštím redirect");
        console.log("IsAndroid:", IsAndroid());
        console.log("IsIos:", IsIos());
        redirectToShop();
    }, []);
    

    return (
        <div></div>
    )
}
