import React from 'react'
import ReactMarkdown from 'react-markdown';

export default function PrivacyPolicyApp() {
    return (
        <div style={{padding: '40px'}}>
            <ReactMarkdown>
                {`**PRIVACY POLICY** 


ENGLISH VERSION:

**Privacy Policy**

This Privacy Policy applies to the LOVEGUARD application (hereinafter referred to as the “Application”) for mobile devices, created by LOVEGUARD s.r.o. (hereinafter referred to as the “Service Provider”) as a Freemium service. This service is intended to be used “AS IS”.

**Information Collection and Use**

The Application collects information when you download and use it. This information may include information such as

* Your device’s Internet Protocol address (e.g. IP address)

* The pages of the Application that you visit, the time and date of your visit, the time spent on those pages

* Time spent on the Application

* The operating system you use on your mobile device

* The Application does not collect precise information about the location of your mobile device.

* The App collects the location of your device, which helps the Service Provider determine your approximate geographic location and use it in the following ways:

* Geolocation Services: The Service Provider uses location data to provide features such as personalized content, relevant recommendations, and location-based services.

* Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider analyze user behavior, identify trends, and improve the overall performance and functionality of the App.

* Third-Party Services: The Service Provider may periodically share anonymized location data with external services. These services help them improve the App and optimize their offerings.

The Service Provider may use the information you provide to contact you from time to time to provide you with important information, requested notifications, and marketing promotions.

To provide you with a better experience while using the App, the Service Provider may require you to provide us with certain personal information, including but not limited to email, full name, gender, age, date of birth, medical records, and address. The information requested by the Service Provider will be retained and used as described in this Privacy Policy.

Third Party Access

Only aggregated, anonymized data is periodically transferred to external services to help the Service Provider improve the App and their services. The Service Provider may share your information with third parties in the ways described in this Privacy Policy.

Please note that the App uses third-party services that have their own Privacy Policies regarding their data handling. Below are links to the privacy policies of the third-party service providers used by the App:

* [Google Play](https://www.google.com/policies/privacy/) 

* [AdMob](https://support.google.com/admob/answer/6128543?hl=cs) 

* [Google Analytics pro Firebase](https://firebase.google.com/support/privacy) 

* [Facebook](https://www.facebook.com/about/privacy/update/printable) 

* [Mixpanel](https://mixpanel.com/legal/privacy-policy/) 

The Service Provider may disclose User-provided and automatically collected information:

as required by law, such as to comply with a subpoena or similar legal process;

when they have a good faith belief that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;

with their trusted service providers who work on their behalf, do not independently use the information we provide to them, and have committed to following the rules set forth in this Privacy Statement.

Opt-out Rights

You can easily stop all information collection by the App by uninstalling it. You may use standard uninstall processes that may be available as part of your mobile device or through a mobile app marketplace or network.

Data Retention Policy

The Service Provider will retain User Provided Data for as long as you use the App and for a reasonable period of time thereafter. If you wish them to delete User Provided Data that you have provided through the App, please contact them at forman@loveguard.cloud and they will respond to you within a reasonable time.

**Children**

The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.

The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personal information from children under the age of 13. In the event that the Service Provider becomes aware that a child under the age of 13 has provided personal information, the Service Provider will immediately delete such information from its servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (forman@loveguard.cloud) so that it can take the necessary steps.

**Security**

The Service Provider is committed to protecting the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect the information that the Service Provider processes and stores.

**Changes**

This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. We encourage you to review this Privacy Policy periodically for any changes, as continued use will be deemed acceptance of all changes.

This Privacy Policy is effective as of August 28, 2024

**Your Consent**

By using the Application, you consent to the processing of your data as set forth in this Privacy Policy now and as amended.

**Contact Us**

If you have any questions regarding privacy when using the Application or have questions regarding the procedures, please contact the Service Provider by email at forman@loveguard.cloud.


______________________________________________________
______________________________________________________
______________________________________________________
______________________________________________________
______________________________________________________
______________________________________________________
______________________________________________________

ČESKÁ VERZE:

**Zásady ochrany osobních údajů** 

  

Tyto zásady ochrany osobních údajů se vztahují na aplikaci LOVEGUARD (dále jen „Aplikace“) pro mobilní zařízení, kterou vytvořila společnost LOVEGUARD s.r.o. (dále jen „poskytovatel služeb“) jako služba Freemium. Tato služba je určena k použití „TAK, JAK JE“. 

  

**Shromažďování a používání informací** 

  

Aplikace shromažďuje informace, když ji stahujete a používáte. Tyto informace mohou zahrnovat informace jako např 

  

* Adresa internetového protokolu vašeho zařízení (např. IP adresa) 

* Stránky aplikace, které navštívíte, čas a datum vaší návštěvy, čas strávený na těchto stránkách 

* Čas strávený na aplikaci 

* Operační systém, který používáte na svém mobilním zařízení 

  

* Aplikace neshromažďuje přesné informace o poloze vašeho mobilního zařízení. 

  

* Aplikace shromažďuje polohu vašeho zařízení, což pomáhá poskytovateli služeb určit vaši přibližnou geografickou polohu a využít ji níže uvedenými způsoby: 

  

* Geolokační služby: Poskytovatel služeb využívá údaje o poloze k poskytování funkcí, jako je personalizovaný obsah, relevantní doporučení a služby založené na poloze. 

* Analýzy a vylepšení: Agregované a anonymizované údaje o poloze pomáhají poskytovateli služeb analyzovat chování uživatelů, identifikovat trendy a zlepšit celkový výkon a funkčnost aplikace. 

* Služby třetích stran: Poskytovatel služeb může pravidelně předávat externím službám anonymizované údaje o poloze. Tyto služby jim pomáhají při vylepšování aplikace a optimalizaci jejich nabídky. 

  

Poskytovatel služeb může použít vámi poskytnuté informace, aby vás čas od času kontaktoval, aby vám poskytl důležité informace, požadovaná upozornění a marketingové propagace. 

  

Pro lepší zážitek při používání aplikace může poskytovatel služeb vyžadovat, abyste nám poskytli určité osobní údaje, včetně, ale bez omezení na e-mail, celé jméno, pohlaví, věk, datum narození, lékařské záznamy, adresu. Informace, které si poskytovatel služeb vyžádá, budou uchovány a použity tak, jak je popsáno v těchto zásadách ochrany osobních údajů. 

  

**Přístup třetí strany** 

  

Externím službám jsou pravidelně předávány pouze agregované, anonymizované údaje, které pomáhají poskytovateli služeb zlepšovat aplikaci a jejich služby. Poskytovatel služeb může sdílet vaše informace s třetími stranami způsoby, které jsou popsány v tomto prohlášení o ochraně osobních údajů. 

  

Vezměte prosím na vědomí, že Aplikace využívá služby třetích stran, které mají vlastní Zásady ochrany osobních údajů o nakládání s údaji. Níže jsou uvedeny odkazy na zásady ochrany osobních údajů poskytovatelů služeb třetích stran používaných aplikací: 

  

* [Služby Google Play](https://www.google.com/policies/privacy/) 

* [AdMob](https://support.google.com/admob/answer/6128543?hl=cs) 

* [Google Analytics pro Firebase](https://firebase.google.com/support/privacy) 

* [Facebook](https://www.facebook.com/about/privacy/update/printable) 

* [Mixpanel](https://mixpanel.com/legal/privacy-policy/) 

  

Poskytovatel služeb může zpřístupnit Uživatelem poskytnuté a automaticky shromážděné informace: 

  

* jak to vyžaduje zákon, jako je například vyhovění předvolání k soudu nebo podobnému právnímu procesu; 

* když se v dobré víře domnívají, že zveřejnění je nezbytné pro ochranu jejich práv, ochranu vaší bezpečnosti nebo bezpečnosti ostatních, vyšetřování podvodu nebo reakci na žádost vlády; 

* se svými poskytovateli důvěryhodných služeb, kteří pracují jejich jménem, nepoužívají nezávisle informace, které jim poskytujeme, a zavázali se dodržovat pravidla stanovená v tomto prohlášení o ochraně osobních údajů. 

  

**Práva na odhlášení** 

  

Veškeré shromažďování informací aplikací můžete snadno zastavit jejím odinstalováním. Můžete použít standardní procesy odinstalace, které mohou být dostupné jako součást vašeho mobilního zařízení nebo prostřednictvím tržiště mobilních aplikací nebo sítě. 

  

**Zásady uchovávání údajů** 

  

Poskytovatel služeb bude uchovávat údaje poskytnuté uživatelem tak dlouho, dokud budete Aplikaci používat, a přiměřenou dobu poté. Pokud si přejete, aby smazal data poskytnutá uživatelem, která jste poskytli prostřednictvím aplikace, kontaktujte je prosím na adrese forman@loveguard.cloud a oni vám v přiměřené době odpoví. 

  

**Děti** 

  

Poskytovatel služeb nepoužívá Aplikaci k vědomému získávání dat od dětí mladších 13 let nebo jejich marketingu. 

  

Aplikace neoslovuje nikoho mladšího 13 let. Poskytovatel služeb vědomě neshromažďuje osobní údaje od dětí mladších 13 let. V případě, že Poskytovatel služeb zjistí, že dítě mladší 13 let poskytlo osobní údaje, Poskytovatel služeb tyto okamžitě vymaže ze svých serverů. Pokud jste rodič nebo opatrovník a víte, že nám vaše dítě poskytlo osobní údaje, kontaktujte prosím poskytovatele služeb (forman@loveguard.cloud), aby mohl podniknout potřebné kroky. 

 

**Zabezpečení** 

  

Poskytovatel služeb se stará o ochranu důvěrnosti vašich informací. Poskytovatel služeb poskytuje fyzické, elektronické a procedurální záruky na ochranu informací, které poskytovatel služeb zpracovává a uchovává. 

  

**Změny** 

  

Tyto zásady ochrany osobních údajů mohou být z jakéhokoli důvodu čas od času aktualizovány. Poskytovatel služeb vás bude informovat o jakýchkoli změnách Zásad ochrany osobních údajů aktualizací této stránky novými Zásadami ochrany osobních údajů. Doporučujeme vám, abyste si tyto Zásady ochrany osobních údajů pravidelně prohlíželi ohledně jakýchkoli změn, protože další používání se považuje za schválení všech změn. 

  

Tyto zásady ochrany osobních údajů jsou účinné od 28.08.2024 

  

**Váš souhlas** 

  

Používáním aplikace souhlasíte se zpracováním vašich údajů, jak je uvedeno v těchto Zásadách ochrany osobních údajů nyní a v našem znění. 

  

**Kontaktujte nás** 

  

Máte-li jakékoli dotazy týkající se ochrany osobních údajů při používání aplikace nebo máte dotazy týkající se postupů, kontaktujte prosím poskytovatele služeb prostřednictvím e-mailu na adrese forman@loveguard.cloud. 

  

* * * `}
            </ReactMarkdown>
        </div>
    )
}
